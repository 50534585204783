import { getIsFeatureEnabledByConst } from '@hip/feature-toggles'
import { Features } from '@hip/interfaces'

export const getIsTykUnauthenticatedEventsWs = getIsFeatureEnabledByConst(
  Features.TYK_UNAUTHENTICATED_EVENTS_WS
)

export const getIsTykAuthenticatedEventsWs = getIsFeatureEnabledByConst(
  Features.TYK_AUTHENTICATED_EVENTS_WS
)

export const getIsTykColleagueAuthenticatedEventsWs = getIsFeatureEnabledByConst(
  Features.TYK_COLLEAGUE_AUTHENTICATED_EVENTS_WS
)
